<template>
  <div class="f-box">
    <div class="handle">
      <div class="h-img"><img src="@/assets/img/qian_02.png" alt=""></div>
      <div class="user">
        <span style="margin-right:0;">签到台编号：</span>
        <span style="margin-right:25px;">{{ taihao }}</span>
        <span style="margin-right:0;">签到责任人：</span>
        <span >{{ username }}</span>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="showList"
              >签到列表</el-dropdown-item> 
            <el-dropdown-item @click.native="back"
              >退出登录</el-dropdown-item> 
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- <div class="h-btn" @click="showList">签到列表</div> -->
    </div>
    <div class="table-box" v-if="!showQList">
      <div class="s-btn">
        <el-input
          v-model="input"
          ref="atf"
          placeholder="请输入身份证号码"
          style="width: 300px"
          @keyup.enter.native="getUserMsg"
        >
          <el-button
            slot="append"
            icon="el-icon"
            @click="getUserMsg"
            style="font-size: 20px"
            >搜索</el-button
          >
        </el-input>
      </div>
      <div class="flex-box" v-if="isBen">
        <el-table :data="tableData" border style="width: 100%" class="table1">
          <el-table-column label="合同编号" prop="contract" align="center">
          </el-table-column>
          <el-table-column label="抽签顺序号" prop="sort" align="center">
          </el-table-column>
           <el-table-column label="签到台" prop="table" align="center">
          </el-table-column>
          <el-table-column prop="power_name" label="权利人姓名" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.power_name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="power_id_card"
            label="权利人身份证号码"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="name" label="签到人姓名" align="center">
          </el-table-column>
          <el-table-column
            prop="id_card"
            label="签到人身份证号码"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="cn_type" label="签到人身份" align="center">
          </el-table-column>
          <el-table-column prop="sign_time" label="签到时点" align="center">
          </el-table-column>
          <!-- <el-table-column label="是否领取抽签臂贴" align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.is_xiong"></el-checkbox>
            </template>
          </el-table-column> -->
          <el-table-column label="是否领取抽签手环及臂贴" align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.is_shou"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="是否提交抽签选房办法" align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.is_method"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="是否提交抽签选房活动通知" align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.is_notify"></el-checkbox>
            </template>
          </el-table-column>
          <!-- <el-table-column label="是否提交授权委托书（受托人）" align="center">
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.is_entrust"
                :disabled="scope.row.type != 2"
              ></el-checkbox>
            </template>
          </el-table-column> -->
          <el-table-column label="备注" align="center"  width="150">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.remark"
                placeholder="请输入"
                size="small"
                style="width: 120px"
              ></el-input>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div
          v-if="tip != ''"
          style="color: red; text-align: center; margin-top: 15px"
        >
          {{ tip }}
        </div> -->
      </div>
      <div class="flex-box" v-else>
        <el-card class="box-card">
          <div class="none-box">
            <span class="none-tip">签到人身份</span>
            <el-radio-group v-model="radio3">
              <el-radio label="5">家属</el-radio>
              <el-radio label="3">员工</el-radio>
            </el-radio-group>
          </div>
          <div class="none-box">
            <span class="none-tip">是否领取活动臂贴</span>
            <el-radio-group v-model="radio2">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </div>
          <div class="none-box">
            <span class="none-tip">抽签顺序号</span>
            <div style="flex:1;">
              <el-input
                v-model="Qsort"
                placeholder="请输入"
                size="small"
                style="flex: 1"
              >
                <template slot="prepend"
                  ><span style="font-size: 16px; line-height: 16px"
                    >VIP</span
                  ></template
                >
              </el-input>
            </div>
          </div>
          <div class="none-box">
            <span class="none-tip">备注</span>
            <el-input
              style="flex: 1"
              type="textarea"
              :rows="3"
              placeholder="请输入备注"
              v-model="bei"
            >
            </el-input>
          </div>
        </el-card>
      </div>
      <div class="sure-btn" @click="sure">确认签到</div>
    </div>
    <div class="table-box" v-else>
      <div class="flex-box">
        <el-button type="primary" style="margin-bottom: 20px" @click="reback">返回</el-button>
        <el-button type="primary" style="margin-bottom: 20px" @click="daoChu">导出</el-button>
        <el-table :data="qList" border style="width: 100%" class="table">
          <el-table-column label="签到人姓名" prop="name" align="center">
          </el-table-column>
          <el-table-column label="签到人身份证号" prop="id_card" align="center">
          </el-table-column>
          <el-table-column label="权利人姓名" prop="power_name" align="center">
          </el-table-column>
          <el-table-column
            label="权利人身份证号"
            prop="power_id_card"
            align="center"
          >
          </el-table-column>
          <el-table-column label="合同号" prop="contract" align="center">
          </el-table-column>
          <el-table-column label="抽签顺序号" prop="sort" align="center">
          </el-table-column>
          <!-- <el-table-column label="签到台号" prop="operate_id" align="center">
          </el-table-column> -->
          <el-table-column
            label="签到责任人"
            prop="operate_name"
            align="center"
          >
          </el-table-column>
          <el-table-column label="备注" prop="remark" align="center">
          </el-table-column>
          <el-table-column
            label="是否领取抽签手环"
            prop="is_shou_cn"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="是否领取抽签臂贴"
            prop="is_xiong_cn"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="是否提交抽签选房办法"
            prop="is_method_cn"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="是否提交抽签选房活动通知"
            prop="is_notify_cn"
            align="center"
          >
          </el-table-column>
          <!-- <el-table-column
            label="是否提交授权委托书（受托人）"
            prop="is_entrust_cn"
            align="center"
          >
          </el-table-column> -->
          <el-table-column label="签到人身份" prop="type_cn" align="center">
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="QData.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      type: 0,
      currentPage: 1,
      showQList: false,
      Qsort: "",
      auto: true,
      bei: "",
      time: "",
      QPeole: {
        contract: "",
        sort: "",
        id: "",
        name: "",
      },
      input: "",
      radio: "",
      radio1: "",
      radio2: 0,
      radio3: 3,
      isBen: true,
      tableData: [],
      idCard: "",
      username: "",
      qList: [],
      QData: [],
      tip: "",
      taihao:"",
    };
  },
  methods: {
    reback() {
      this.showQList = false;
    },
    showList() {
      this.currentPage = 1;
      this.getQlist();
      this.showQList = true;
    },
    // 获取签到列表
    getQlist() {
      this.$http
        .post("/index/sign_list", { page: this.currentPage })
        .then((res) => {
          console.log(res);
          this.QData = res.data.data;
          this.qList = res.data.data.list;
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getQlist();
    },
    clearData() {
      this.tableData = [];
      this.input = this.radio = this.radio1 = this.idCard = this.Qsort =  "";
      this.tip = "";
      this.radio2 = 0;
      this.radio3 = 3;
      this.isBen = true;
      this.QPeole = { contract: "", sort: "", id: "", name: "" };
      this.autoFocus();
    },
    autoFocus() {
      this.$nextTick(() => {
        this.$refs["atf"].$refs.input.focus();
      });
    },
    back() {
      this.$router.push({ path: "/" });
      localStorage.clear();
    },
    sure() {
      if (this.isBen) {

        console.log(this.tableData);
        this.$http.post('/index/sign_info_submit',{detail:this.tableData}).then(res=>{
          if(res.data.code == 0){
              $success()
              this.clearData()
          }
        })
      } else {
        if(this.Qsort  == "") return  this.$message({showClose: true,message: '请输入抽签顺序号！', type: 'error' });
        let dt = {
          type: parseInt(this.radio3),
          is_xiong: parseInt(this.radio2),
          remark: this.bei,
          id_card: this.idCard,
          sort: "vip" + this.Qsort,
        };
        this.$http.post("/index/sign_other_submit", dt).then((res) => {
          if (res.data.code == 0) {
            $success();
            this.clearData();
          }
        });
      }
    },
    daoChu(){
      // let href= $baseUrl+'index/export/export_sign'
      let uin = localStorage.getItem('uin')
      window.location.href = $baseUrl+`export/export_sign?useruid=${uin}`
    },
    getUserMsg() {
      let _this = this;
      this.tableData = [];
      this.QPeole = { contract: "", sort: "", id: "", name: "" };
      this.radio = this.radio1 = this.radio2 = this.radio3 = this.bei = "";
      if (this.input == "")
        return this.$message({ message: "请输入身份证号码", type: "warning" });
      let dt = { id_card: this.input };
      this.idCard = this.input;
      this.$http.post("/index/sign_info", dt).then((res) => {
        if (res.data.code == 0) {
          // this.tip = res.data.remark;
          this.isBen = true;
          this.type = res.data.data.type;
          res.data.data.forEach((item) => {
            item.is_shou = false;
            item.is_xiong = false;
            item.is_method = false;
            item.is_notify = false;
            item.is_entrust = false;
            item.remark = "";
            item.sign_time = res.data.sign_time
          });
          // this.time = res.data.sign_time;
          // this.QPeole.contract = res.data.data.contract
          this.QPeole.id = res.data.operate.taihao + "";
          // this.QPeole.sort = res.data.data.sort
          this.QPeole.name = res.data.operate.name;
          _this.tableData = res.data.data;
        } else {
          this.isBen = false;
        }
      });
    },
  },
  created() {
    this.autoFocus();
    this.username = localStorage.getItem("name");
    this.taihao = localStorage.getItem('taihao')
  },
};
</script>
<style lang="less" scoped>
.f-box {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}
.table-box {
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 1;
}
.handle {
  position: relative;
  background: #b5011d;
  height: 60px;
  line-height: 60px;
  padding: 0 30px;
  color: gold;
  font-size: 24px;
  .user {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(0, -50%);
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 10px;
    }
    span {
      margin-right: 15px;
    }
  }
}
.s-btn {
  margin: 30px auto 0;
  text-align: center;
}
.s-msg {
  padding: 90px 0px 0;
  font-size: 16px;
  line-height: 22px;
  .s-title {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 10px;
    line-height: 24px;
    margin-bottom: 5px;
    width: 200px;
    &::after {
      content: "";
      width: 120px;
      background: linear-gradient(to right, #000, #fff);
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .t-msg {
    font-size: 14px;
    line-height: 40px;
    .tag {
      & > div {
        width: 300px;
        display: inline-block;
        margin-right: 68px;
      }
      span:first-child {
        margin-right: 20px;
        display: inline-block;
        color: #999;
      }
    }
  }
}
.s-radio {
  width: 1104px;
  padding: 0 30px;
  margin-top: 10px;
  span {
    font-size: 14px;
    color: #999;
    margin-right: 5px;
  }
}
.sure-btn {
  width: 120px;
  height: 40px;
  background: #b7001d;
  font-size: 14px;
  color: gold;
  text-align: center;
  border-radius: 6px;
  line-height: 40px;
  cursor: pointer;
  margin: 70px auto 0;
}
.flex-box {
  margin: 60px 40px 0;
  .none-box {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .none-tip {
      font-size: 18px;
      font-weight: bold;
      display: inline-block;
      width: 190px;
    }
  }
}
/deep/.el-table thead th.is-leaf {
  color: gold;
  background-color: #b7001d !important;
}
/deep/.el-table thead.is-group th {
  color: gold;
  background-color: #b7001d !important;
}
.h-btn {
  display: inline-block;
  width: 80px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  color: #fff;
  font-weight: bold;
  right: 200px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  border-radius: 4px 4px 0 0;
}
/deep/.el-card {
  width: 500px;
  margin: 0 auto;
  color: #000;
}
// /deep/.table1 thead tr:first-child th:nth-child(2),/deep/.table1 thead tr:first-child th:nth-child(4){
//     color: #000;
//     background-color: #fff !important;
//     font-weight: normal!important;
// }
.card2 {
  width: 200px !important;
  position: absolute;
  left: 40px;
  top: 0px;
}
.card2 {
  color: #b7001d;
  font-weight: bold;
  span {
    color: #000;
    font-weight: normal;
  }
}
.h-img{
  line-height: 0;
  height: 100%;
  overflow: hidden;
  img{
    height: 100%;
    margin-top: 4px;
  }
}
/deep/.el-pagination{
  margin: 20px 0;
}
::-webkit-scrollbar {
    display:none;
    width:0;
    height:0;
    color:transparent;
}
</style>